<!-- Maintenance start-->
<div class="error-wrapper maintenance-bg">
    <div class="container">
        <ul class="maintenance-icons">
            <li><i class="fa fa-cog"></i></li>
            <li><i class="fa fa-cog"></i></li>
            <li><i class="fa fa-cog"></i></li>
        </ul>
        <div class="maintenance-heading">
            <h2 class="headline">Redirecting....</h2>
        </div>
        <h4 class="sub-content">Thank You For Patience</h4>
    </div>
</div>
<!-- Maintenance end-->