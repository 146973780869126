import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { environment } from '../../../environments/environment';
import * as Oidc from 'oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavService } from 'src/app/core/services/nav.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();
  private manager = new UserManager(getClientSettings());
  private user: User | null;
  private userProfile: UserProfile | null;

  get _user(): User {
    return this.user;
  }

  get _userProfile(): UserProfile {
    return this.userProfile;
  }

  constructor(private permissionsService: NgxPermissionsService,
    private _http: HttpClient,
    private navService: NavService) {
    this.manager.clearStaleState();
    // this.setUser();
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  signIn() {
    this.manager.signinRedirect();
  }

  signOut() {
    this.manager.signoutRedirect();
  }

  async silentRenew() {
    const config = {
      userStore: new Oidc.WebStorageStateStore({ store: window.sessionStorage })
    };
    this.user = await new Oidc.UserManager(config).signinSilentCallback();
  }

  setUser() {
    this.manager.getUser().then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      this.user = user;
      this._authNavStatusSource.next(this.isAuthenticated());
    });
  }

  async setUserFromStorage(user: User): Promise<User> {
    this.user = user;
    return new Promise<User>((resolve, reject) => {
      resolve(user);
    });
  }

  async handleSignInCallBack() {
    this.user = await this.manager.signinRedirectCallback();
    this.setUserClaimsPermissionsAndMenu(this.user);
  }

  setUserClaimsPermissionsAndMenu(user: User): void {
    this.userProfile = user;
    const permissions = user.profile['permissions'];

    if (permissions) {
      this.setUserInformation(permissions, user.profile.role, user.profile.EntityID);
      this._authNavStatusSource.next(this.isAuthenticated());
    } else {
      this.getUserPermissions(user.profile.sub).subscribe(res => {
        if (res) {
          this.setUserInformation(res, user.profile.role, user.profile.EntityID);
          this.navService.setUserMenu(user.profile.role, res);
          this._authNavStatusSource.next(this.isAuthenticated());
        }
      });
    }
  }

  // Handle the case where user refreshes and permissions are loaded after the route guard is activated
  setUserClaimsPermissionsAndMenuPromise(user: User): Promise<void> {
    this.userProfile = this.user;
    return new Promise<void>((resolve, reject) => {
      const permissions = user.profile['permissions'];
      if (permissions) {
        this.setUserInformation(permissions, user.profile.role, user.profile.EntityID);
        this._authNavStatusSource.next(this.isAuthenticated());
        resolve();
      } else {
        this.getUserPermissions(user.profile.sub)
          .pipe(map(res => null))
          .toPromise()
          .then((res => {
            if (res) {
              this.setUserInformation(res, user.profile.role, user.profile.EntityID);
              this._authNavStatusSource.next(this.isAuthenticated());
              resolve(res);
            } else {
              reject(PermissionsEnum.NO_PERMISSIONS_FOUND);
            }
          })).catch(error => {
            reject(PermissionsEnum.API_ERROR);
          });
      }
    });
  }

  setUserRoles(roles): string[] {
    let userRoles: string[] = [];
    if (roles) {
      if (typeof roles === 'string') userRoles.push(...roles.split('"'));
      userRoles.push(...roles);
    } else userRoles = roles;
    return userRoles;
  }

  getUserPermissions(userId): any {
    let params = new HttpParams();
    params = params
      .append('Sub', userId);
    return this._http.get(`${environment.isAdminBaseUrl}/api/v1/users/GetPermissionsBySub`, { params });
  }

  setUserInformation(permissions, roles, entityId) {
    this.userProfile.userRoles = this.setUserRoles(roles) || [];
    this.userProfile.userPermissions = this.setUserPermissions(permissions);
    this.userProfile.entityId = entityId;
    this.setUserMenu(this.userProfile.userPermissions, this.userProfile.userRoles);
  }

  setUserMenu(permissions, roles) {
    this.navService.setUserMenu(roles, permissions);
  }

  setUserPermissions(permissions): string[] {
    let userPermissions: string[] = [];
    if (permissions) {
      if (typeof permissions === 'string') userPermissions.push(...permissions.split('"'));
      userPermissions.push(...permissions);
    } else userPermissions = permissions;

    this.permissionsService.loadPermissions(userPermissions);
    return userPermissions;
  }
}

export enum PermissionsEnum {
  NO_PERMISSIONS_FOUND = 1,
  API_ERROR
}
export class UserProfile extends User {
  userRoles?: string[];
  userPermissions?: string[];
  entityId?: number
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: window['env']['IS_BASE_URL'] || 'https://accounts-stg.hala.com',
    client_id: 'halalah_merchants',
    redirect_uri: `${window.origin}/auth-callback`,
    post_logout_redirect_uri: window.origin,
    response_type: 'id_token token',
    scope: 'openid profile roles scope_payments_gw_api',
    loadUserInfo: true,
    accessTokenExpiringNotificationTime: 60,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.origin}/auth-callback`
  };
}
