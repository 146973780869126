import { ContentLoaderComponent } from './components/contentLoader/content-loader.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
// Directives
// Services
// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { FullPageLoaderComponent } from './components/full-page-loader/full-page-loader.component';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    ContentLoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    LoaderComponent,
    TapToTopComponent,
    FullPageLoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxPermissionsModule.forRoot(),
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot(),
    NgSelectModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbCollapseModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    ContentLoaderComponent,
    TapToTopComponent,
    SweetAlert2Module,
    ToastrModule,
    FullPageLoaderComponent,
    NgSelectModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgxPermissionsModule
  ],
})
export class SharedModule { }
