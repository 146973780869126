import { AuthService, getClientSettings, PermissionsEnum } from './../../shared/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'oidc-client';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isAuthenticated()) {
      return true;
    }
    return new Promise<boolean>((resolve, reject) => {
      const clientSettings = getClientSettings();
      const key = `oidc.user:${clientSettings.authority}:${clientSettings.client_id}`;
      const jwtToken = sessionStorage.getItem(key);
      if (jwtToken) {
        const userFromToken = User.fromStorageString(jwtToken);
        // expired cases
        if (userFromToken.expired) {
          localStorage.setItem('redirect_url', state.url);
          this.authService.signIn();
          resolve(false);
        }
        // end expired cases
        this.authService.setUserFromStorage(userFromToken).then(user => {
          return this.authService.setUserClaimsPermissionsAndMenuPromise(user).then(permissions => {
            resolve(true);
          }).catch(error => {
            this.router.navigate(['../error-page/error-401'], {queryParams: {error}});
            reject();
          });
        });
      } else {
        localStorage.setItem('redirect_url', state.url);
        this.authService.signIn();
        return false;
      }
    });
  }
}
