import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class BaseHttpService {

    private httpWithoutInterceptor: HttpClient;
    constructor(private http: HttpClient, httpBackEnd: HttpBackend) {
        this.httpWithoutInterceptor = new HttpClient(httpBackEnd);
    }
    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${path}`, { params });
      }
      put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(
          `${path}`,
          JSON.stringify(body),
          {headers: {
            'content-type' : 'application/json; charset=utf-8'
          }}
        );
      }
      post(path: string, body: Object = {}, options: Object = {}): Observable<any> {
        return this.http.post(
          `${path}`,
          JSON.stringify(body),
          {...options, headers: {
            'content-type' : 'application/json; charset=utf-8'
          }}
        );
      }
      postFile(path: string, body: any, options: Object = {}): Observable<any> {
        return this.http.post(
          `${path}`, body
        );
      }
      delete(path): Observable<any> {
        return this.http.delete(
          `${path}`
        );
      }
      _get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.httpWithoutInterceptor.get(`${path}`, { params });
      }
      _put(path: string, body: Object = {}): Observable<any> {
        return this.httpWithoutInterceptor.put(
          `${path}`,
          JSON.stringify(body)
        );
      }
      _post(path: string, body: Object = {}, options: Object = {}): Observable<any> {
        return this.httpWithoutInterceptor.post(
          `${path}`,
          JSON.stringify(body),
          options
        );
      }
      _delete(path): Observable<any> {
        return this.httpWithoutInterceptor.delete(
          `${path}`
        );
      }
}