// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: typeof window['env']['isProduction'] !== 'boolean' ? true : window['env']['isProduction'],
  baseAPIUrl: window['env']['API_URL'] || 'https://localhost:2918',
  usersGatewayBaseURL: window['env']['USERSGATEWAY_BASEURL'] || 'https://api-stg.hala.com/web/userEntity',
  isAdminBaseUrl: window['env']['IS_ADMIN_BASE_URL'] || 'https://accounts-admin-stg.hala.com/api',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
