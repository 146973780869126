import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export class Menu {
	headTitle1?: string;
	headTitle2?: string;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	rolesAllowed: string[];
	permissionsAllowed: string[];
	enabled?: boolean = true;
}
@Injectable({
	providedIn: 'root'
})
export class NavService implements OnDestroy {

	MENUITEMS: Menu[];
	items = new BehaviorSubject<Menu[]>([]);
	menuItemsToShow = new BehaviorSubject<Menu[]>([]);
	constructor(private router: Router) {
		this.setMenuItems();
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search = false;

	// Language
	public language = false;

	// Mega Menu
	public megaMenu = false;
	public levelMenu = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen = false;


	setMenuItems() {
		this.MENUITEMS = [
			{
				title: 'Dashboard',
				icon: 'home',
				type: 'link',
				active: false,
				rolesAllowed: [],
				permissionsAllowed: ['merchant_portal_dashboard_read'],
				path: '/dashboard'
			},
			{
				title: 'Beneficiaries',
				icon: 'user-plus',
				type: 'link',
				active: false,
				path: '/beneficiaries',
				rolesAllowed: [],
				permissionsAllowed: ['merchant_portal_beneficiaries_read', 'merchant_portal_beneficiaries_write']
			},
			{
				title: 'Requests',
				icon: 'dollar-sign',
				type: 'link',
				active: false,
				path: '/requests/all',
				permissionsAllowed: [],
				rolesAllowed: []
			},
			{
				title: 'Statement',
				icon: 'dollar-sign',
				type: 'sub',
				active: false,
				permissionsAllowed: ["merchant_portal_statement_read"],
				rolesAllowed: [],
				children: [
					{
						path: '/statement/',
						title: 'Statement',
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: ["merchant_portal_statement_read"]
					},
					{
						path: '/statement/salesmen',
						title: 'Salesmen Statement',
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: ["merchant_portal_salesmen_statement_read"],
					},
				]
			},
			{
				title: 'Payment',
				icon: 'slack',
				type: 'sub',
				active: false,
				rolesAllowed: [],
				permissionsAllowed: ['merchant_portal_payment_write'],
				children: [
					{
						path: '/payment/hala-transfer',
						title: 'Hala Transfer',
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: []
					},
					{
						path: '/payment/iban-transfer',
						title: 'IBAN Transfer',
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [],
					},
				]
			},
			{
				title: 'Utils',
				icon: 'slack',
				type: 'sub',
				active: false,
				rolesAllowed: [],
				permissionsAllowed: [],
				children: [
					{
						path: '/utils/iban-validator',
						title: 'IBAN Validator',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: []
					},
					{
						path: '/utils/hala-user-validator',
						title: 'Check If Hala User',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: [],
					},
					{
						path: '/utils/link-cards',
						title: 'Link Cards',
						icon: 'box',
						type: 'link',
						rolesAllowed: [],
						permissionsAllowed: ['merchant_portal_link_cards']
					}
				]
			}
		];


		this.items.next(this.MENUITEMS);
		this.menuItemsToShow.next(this.MENUITEMS);
	}

	setUserMenu(roles: any, permissions: any): Menu[] {


		const userRoles: string[] = [];
		const userPermissions = [];

		if (roles) {
			if (typeof roles === 'string') userRoles.push(...roles.split('"'));
			userRoles.push(...roles);
		}



		if (permissions) {
			if (typeof permissions === 'string') userPermissions.push(...roles.split('"'));
			userPermissions.push(...permissions);
		}



		// No processing needed if super Admin.
		if (userRoles.includes('SuperAdmin'))
			return;
		const userMenu: Menu[] = this.getMenuItems(this.MENUITEMS, userRoles, userPermissions);

		if (userMenu.length > 0)
			this.menuItemsToShow.next(userMenu);


		return userMenu;
	}

	getMenuItems(items: Menu[] = this.MENUITEMS, userRoles, userPermissions): Menu[] {
		const menuItems: Menu[] = [];
		items.forEach(item => {
			const itemToAdd = { ...item };
			delete itemToAdd.children;
			if (this.checkIfMenuItemAllowed(item, userRoles, userPermissions)) {
				if (item.children) {
					itemToAdd.children = this.getMenuItems(item.children, userRoles, userPermissions);
				}
				menuItems.push(itemToAdd);
			}
		});
		return menuItems;
	}

	checkIfMenuItemAllowed(item: Menu, userRoles, userPermissions): boolean {
		if (!item.rolesAllowed.length && !item.permissionsAllowed.length) return true;
		if (item.rolesAllowed.length) {
			const filteredArray = userRoles.filter(value => item.rolesAllowed.includes(value));
			if (filteredArray.length) return true;
		}
		if (item.permissionsAllowed.length) {
			const filteredArray = userPermissions.filter(value => item.permissionsAllowed.includes(value));
			if (filteredArray.length) return true;
		}
		return false;
	}

	// Array


	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}
}
