import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { HeaderComponent } from './components/header/header.component';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SigninOidcComponent } from './components/signin-oidc/signin-oidc.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NavService } from './services/nav.service';
import { AuthService } from '../shared/services/auth.service';
import { UserAccountComponent } from './components/header/user-account/user-account.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { ContentComponent } from './layout/content/content.component';
import { FullComponent } from './layout/full/full.component';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule
  ],
  declarations: [
    FullComponent,
    ContentComponent,
    HeaderComponent,
    SidebarComponent,
    SigninOidcComponent,
    UnauthorizedComponent,
    UserAccountComponent,
    FeatherIconsComponent
  ],
  exports: [
    ContentComponent,
    FullComponent,
    SigninOidcComponent
  ],
  providers: [
    NavService,
    AuthService,
    AuthGuard
  ]
})
export class CoreModule { }
