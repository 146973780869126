import { LoaderAndErrorService } from './../services/loading.service';
import { ToastrService } from 'ngx-toastr';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse

} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, of, EMPTY, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {

    /**
     *
     */
    constructor(private injector: Injector, private loaderAndErrorService: LoaderAndErrorService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)

            .pipe(

                retry(1),

                catchError((error: HttpErrorResponse) => {
                    const toastrService = this.injector.get(ToastrService);
                    let errorMessage = '';

                    if (error.error instanceof ErrorEvent) {

                        // client-side error

                        errorMessage = `Error: ${error.error.message}`;

                    } else {

                        // server-side error
                        switch (error.status) {
                            case 401:
                                // console.log('UnAuthorized error', error);
                                errorMessage = `Error Code: ${error.status}. You are Unauthorized to perform this action`;
                                break;
                            case 400:
                                // console.log('Bad request error', error);
                                errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
                                break;
                            case 404:
                                // console.log('404', error);
                                errorMessage = `Error Code: ${error.status}\nMessage: A requested resource could not be found`;
                                break;
                            default:
                                // console.log('Default Error', error);
                                errorMessage = `An Error Occured. We are looking into it!`;
                                break;
                        }
                    }
                    // toastrService.error(errorMessage);
                    this.loaderAndErrorService.setError(errorMessage);
                    // return of(new HttpResponse({}));
                    // this._loading.setError(errorMessage);
                    return throwError(errorMessage);
                })
            )
    }
}
