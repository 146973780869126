import { Routes } from '@angular/router';
import { MaintenanceComponent } from 'src/app/pages/maintenance/maintenance.component';

export const full: Routes = [
  {
    path: 'error-page',
    loadChildren: () => import('../../pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('../../pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'auth-callback',
    component: MaintenanceComponent
  }
];
