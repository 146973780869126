import { fadeInAnimation } from './../../../shared/data/router-animation/router-animation';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';
import { LayoutService } from '../../services/layout.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {

  constructor(public navServices: NavService,
    public layout: LayoutService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {

  }

}
