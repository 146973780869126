import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base.service';
import { ValidateIBANResponse, ValidateUserResponse } from '../models/beneficiary.model';

@Injectable({
    providedIn: 'root'
})
export class UtilsService extends BaseHttpService {

    private apiURL = `${environment.baseAPIUrl}/v1/utilities`;
    loading$ = new BehaviorSubject<boolean>(false);

    ValidateIBAN(body): Observable<ValidateIBANResponse> {
        let params = new HttpParams();
        if (body) {
            const keys = Object.keys(body);
            keys.forEach(key => params = params.append(key, body[key]));
        }
        return this.get(`${this.apiURL}/validate-iban`, params);
    }

    CheckIfHalaUser(body): Observable<ValidateUserResponse> {
        let params = new HttpParams();
        if (body) {
            const keys = Object.keys(body);
            keys.forEach(key => params = params.append(key, body[key]));
        }
        return this.get(`${this.apiURL}/IsUserExists`, params);
    }

    LinkJahezCards(excelFile): Observable<any> {
        const formData = new FormData();
        formData.append('file', excelFile, excelFile.name);
        return this.postFile(`${this.apiURL}/Link-Jahez-Cards`, formData);
    }

    GetJahezCards(body): Observable<any> {
        return this.post(`${this.apiURL}/Get-Jahez-Cards`, body);
    }
    GetJahezCardsForStatus(body): Observable<any> {
        return this.post(`${this.apiURL}/Get-Jahez-Cards-file`, body);
    }
    ExportJahezCards(body): Observable<any> {
        return this.post(`${this.apiURL}/Export-Jahez-Cards`, body);
    }
    showLoader() {
        this.loading$.next(true);
    }

    hideLoader() {
        this.loading$.next(false);
    }
}
