import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'beneficiaries',
    loadChildren: () => import('../../components/beneficiaries/beneficiaries.module').then(m => m.BeneficiariesModule)
  },
  {
    path: 'statement',
    loadChildren: () => import('../../components/statements/statement.module').then(m => m.StatementModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('../../components/requests/requests.module').then(m => m.RequestsModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('../../components/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'utils',
    loadChildren: () => import('../../components/utils/utils.module').then(m => m.UtilsModule)
  }
];
