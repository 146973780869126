<div class="media profile-media align-items-center" *ngIf="authService.authNavStatus$ | async">
  <img class="b-r-10" src="assets/images/dashboard/profile-1.png" width="37" height="37" alt="">
  <div class="media-body">
    <span>{{authService._user.profile.email}} <i class="middle fa fa-angle-down"></i></span>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li>
    <a (click)="authService.signOut()">
      <i [attr.data-feather]="'log-out'"></i>
      <span>Log out</span>
    </a>
  </li>
</ul>
